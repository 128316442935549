import { useCallback, useEffect, useMemo, useState, type FC } from "react";
import type { FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector, useReportCommonStates } from "hooks";
import { Table } from "@vilocnv/allsetra-core";
import { Box, useTheme } from "@mui/material";
import ReportsTopbar from "components/common/reports/ReportsTopbar/ReportsTopbar";
import ReportsFilterBar from "components/common/reports/ReportFiltersBar/ReportsFilterBar";
import ReportsCommonFilterForm from "components/forms/Reports/ReportsCommonFilterForm";
import { ACTIVITY_TABLE_COLUMNS } from "app/data/constants/Reports/activityReportsConstants";
import { selectAccountKeysState } from "app/data/selectors";
import {
  buildActivityReportPayload,
  handleDateRange
} from "app/data/helpers/ReportHelpers/activityReportHelpers";
import {
  generateReportThunk,
  getAllKeysByAccountThunk,
  resetReports,
  setReportsFilterData
} from "app/features";
import ActivityExpendableRowCard from "components/cards/ReportCards/ActivityExpendableRowCard.tsx/ActivityExpendableRowCard";
import {
  commonReportsDataFormatter,
  commonReportsFilterFormValidationSchema,
  customStyles,
  DEFAULT_FILTER_VALUES,
  reportsCommonFormInitialValues
} from "app/data/helpers/ReportHelpers/commonReportHelpers";

const ActivityReports: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const {
    accountGroups,
    dateFormat,
    dateValues,
    drawerSelectedAccountId,
    filterOpen,
    handleObjectsSearch,
    handleOnChangeReportFilterForm,
    objectTypes,
    objectTypesLoading,
    objectsLoading,
    report,
    reportsLoading,
    selectedLocalObjects,
    setDateFormat,
    setDateValues,
    setFilterOpen
  } = useReportCommonStates();

  const [filterValues, setFilterValues] = useState<any>(DEFAULT_FILTER_VALUES);
  const [openModal, setOpenModal] = useState(false);
  const [apiPayload, setApiPayload] = useState(null);
  const { t } = useTranslation(["translation", "tableHeadingsTranslation"]);

  const { accountKeys, loading: accountKeysLoading } = useAppSelector(
    selectAccountKeysState
  );

  const formattedData = useMemo(
    () => commonReportsDataFormatter(report, "object", dateFormat),
    [report, dateFormat]
  );

  const tableColumns = useMemo(() => ACTIVITY_TABLE_COLUMNS(t), [t]);

  const activityRegistrationFiltersSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true);
    setFilterValues(values);
    const payload = await buildActivityReportPayload(
      apiPayload,
      values,
      dateValues
    );
    dispatch(setReportsFilterData(payload));
    setApiPayload(payload);

    formikHelpers.setSubmitting(false);
    setFilterOpen(false);
  };

  const handleDateChange = useCallback((value: any) => {
    setDateValues(value);
  }, []);

  const handleDateRangeSubmit = () => {
    const newPayload = handleDateRange(filterValues, dateValues, apiPayload, 6);
    setApiPayload(newPayload);
  };

  const handleLoadReport = () => {
    dispatch(setReportsFilterData(apiPayload));
    dispatch(generateReportThunk(apiPayload));
  };

  useEffect(() => {
    dispatch(resetReports());
    handleDateRangeSubmit();
  }, []);

  useEffect(() => {
    if (drawerSelectedAccountId !== null) {
      dispatch(getAllKeysByAccountThunk(drawerSelectedAccountId));
    }
  }, [drawerSelectedAccountId]);

  return (
    <Box>
      <ReportsTopbar
        dropdownTitle={t("reports.activityReport")}
        disabled={reportsLoading}
      />
      <Box mx={4}>
        <ReportsFilterBar
          setFilterOpen={setFilterOpen}
          dateFormat={dateFormat}
          setDateFormat={setDateFormat}
          handleDateChange={handleDateChange}
          dateValues={dateValues}
          handleDateRangeSubmit={handleDateRangeSubmit}
          dateRangeLoading={reportsLoading}
          handleLoadReport={handleLoadReport}
          disableExportButton={formattedData.length === 0}
          hideDateFormats={true}
          reportType={6}
          reportExportTypeValue={2}
          openModal={openModal}
          setOpenModal={setOpenModal}
          exportModalButton={true}
          hideExportButton={true}
        />
        <ReportsCommonFilterForm
          fetchOnDebounce={handleObjectsSearch}
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          onSubmit={activityRegistrationFiltersSubmitHandler}
          groups={accountGroups}
          objectTypes={objectTypes}
          objects={selectedLocalObjects}
          keys={accountKeys}
          dataLoading={
            objectsLoading || objectTypesLoading || accountKeysLoading
          }
          //@ts-ignore
          onChange={handleOnChangeReportFilterForm}
          formInitialValues={reportsCommonFormInitialValues}
          formValidations={commonReportsFilterFormValidationSchema}
          theme={theme}
          size="customSize"
        />
        <Table
          columns={tableColumns}
          data={formattedData}
          progressPending={reportsLoading}
          expandableRows
          expandOnRowClicked
          expandableRowsComponent={ActivityExpendableRowCard}
          pagination={false}
          nestedRowPadding={"16px"}
          headerPadding={"50px"}
          translator={t}
          customStyles={customStyles}
        />
      </Box>
    </Box>
  );
};

export default ActivityReports;
