import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InfoWindowF, MarkerF } from "@react-google-maps/api";
import {
  MapObjectCard,
  ObjectMetadataTags,
  utils
} from "@vilocnv/allsetra-core";

// Data
import { isEmpty } from "lodash";
import { useAppDispatch, useAppSelector } from "hooks";
import { setActiveObjectId } from "app/features";
import { selectSpecificObjectState } from "app/data/selectors";
import { SkeletonWrapper, Skeleton } from "./ObjectMarker.styled";
import { useTranslation } from "react-i18next";

function createKey(location: any) {
  return location.lat + location.lng;
}

interface Props {
  marker: any;
  selectedMarkerLoading: boolean;
  clusterer: any;
  setSelectedCluster: (clusterData: any) => void;
  setSelectedMarker: (id: string) => void;
}

const ObjectMarker: FC<Props> = ({
  marker,
  selectedMarkerLoading,
  clusterer,
  setSelectedCluster,
  setSelectedMarker
}) => {
  const [icon, setIcon] = useState();
  const [showInfoWindow, setShowInfoWindow] = useState(true);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (marker) {
      const direction =
        marker?.direction && marker?.direction > 0
          ? marker?.direction
          : marker?.lastReportedDirection && marker?.lastReportedDirection > 0
            ? marker?.lastReportedDirection
            : marker?.lastReportedSpeed;

      const iconWithDirection = utils.getMapIcon(marker.iconUrl, direction);
      setIcon(iconWithDirection ?? marker.iconUrl);
    }
  }, [marker]);

  // Global State
  const { activeObjectId, markerLoading, selectedMarker } = useAppSelector(
    selectSpecificObjectState
  );

  const handleIconClick = () => {
    dispatch(setActiveObjectId(marker.uniqueId ?? ""));
    setSelectedCluster(null);
    setShowInfoWindow(true);
  };

  const handleCloseMarker = () => {
    dispatch(setActiveObjectId(null));
    window.localStorage.removeItem("selectedObjectId");
    setShowInfoWindow(false);
  };

  return (
    <MarkerF
      key={createKey(marker)}
      position={{ lat: marker.lat || 0, lng: marker.lng || 0 }}
      clusterer={clusterer}
      onClick={handleIconClick}
      onMouseOver={() => setSelectedMarker(marker?.uniqueId)}
      onMouseOut={() => setSelectedMarker("")}
      icon={icon}
    >
      {activeObjectId === marker.uniqueId && showInfoWindow && (
        <InfoWindowF
          position={{ lat: marker.lat || 0, lng: marker.lng || 0 }}
          onCloseClick={handleCloseMarker}
        >
          <MapObjectCard
            label={marker.name || t("typography.na")}
            id={marker.aNumber || t("typography.na")}
            loading={false}
            location={{
              isLoading: markerLoading,
              address: selectedMarker?.location?.resolvedAddress,
              latitude: selectedMarker?.location?.latitude,
              longitude: selectedMarker?.location?.longitude
            }}
            accuracy={marker.accuracy || t("typography.na")}
            time={
              markerLoading
                ? utils.formatDate({
                    date:
                      marker?.formattedDate &&
                      !isNaN(new Date(marker.formattedDate).getTime())
                        ? marker.formattedDate
                        : ""
                  })
                : utils.formatDate({
                    date:
                      selectedMarker?.location?.date &&
                      !isNaN(new Date(selectedMarker.location.date).getTime())
                        ? selectedMarker.location.date
                        : ""
                  })
            }
            handleObjectNameClick={() =>
              navigate({
                pathname: `/dashboard/objects/details/${marker.uniqueId}`
              })
            }
            translator={t}
          >
            {!isEmpty(selectedMarker) && !markerLoading ? (
              <ObjectMetadataTags
                translator={t}
                object={selectedMarker}
                direction={"row"}
              />
            ) : (
              <SkeletonWrapper>
                <Skeleton variant="rectangular" />
              </SkeletonWrapper>
            )}
          </MapObjectCard>
        </InfoWindowF>
      )}
    </MarkerF>
  );
};

export default ObjectMarker;
